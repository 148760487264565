import React from 'react';
import { connect } from 'react-redux';
import { Button, Heading2 } from '../ui';
import { ImportHeaderButtonsWrapper, ImportHeaderWrapper, ImportSectionWrapper } from '../ui/Layout/ImportLayout';
import importActions from '../../redux/import/actions';
import { IconArrowLeft, IconCircledCheck, IconReset } from '../ui/Icons';

/**
 * This component displays the header and the action buttons of the import interface.
 * @param {boolean} showActionButtons
 * @param {function} onCancelButton
 * @param {function} onNavigateBackButton
 * @param {object} importFile
 * @param {function} executeImport
 * @param {function} clearImportFile
 */
const ImportHeaderComponent = ({
  showActionButtons,
  onCancelButton,
  onNavigateBackButton,
  importFile,
  executeImport,
  clearImportFile
}) => {
  const handleFinalizeImport = () => {
    executeImport(importFile);
  };

  const handleCancelImport = () => {
    clearImportFile();
    onCancelButton();
  };

  const renderActionButtons = () => {
    if (showActionButtons) {
      return (
        <ImportHeaderButtonsWrapper>
          <Button type="secondary" onClick={handleCancelImport}>
            <IconReset /> Neuer Upload
          </Button>
          <Button type="primary" onClick={handleFinalizeImport}>
            <IconCircledCheck />
            Importieren
          </Button>
        </ImportHeaderButtonsWrapper>
      );
    }

    return <React.Fragment />;
  };

  return (
    <ImportHeaderWrapper>
      <ImportSectionWrapper>
        <Button type="navigation" onClick={onNavigateBackButton} icon={<IconArrowLeft />} />
        <Heading2>Import</Heading2>
      </ImportSectionWrapper>
      {renderActionButtons()}
    </ImportHeaderWrapper>
  );
};

const { executeImport, clearImportFile } = importActions;

const mapDispatchToProps = {
  executeImport,
  clearImportFile
};

const mapStateToProps = (state) => ({
  importFile: state.Import.get('importFile')
});

ImportHeaderComponent.displayName = 'ImportHeader';

export const ImportHeader = connect(mapStateToProps, mapDispatchToProps)(ImportHeaderComponent);
