const actions = {
  LOAD_CLAIMS: 'LOAD_CLAIMS',
  LOAD_CLAIMS_SUCCESS: 'LOAD_CLAIMS_SUCCESS',
  LOAD_CLAIMS_ERROR: 'LOAD_CLAIMS_ERROR',
  LOAD_CLAIMS_BY_POLICY_ID: 'LOAD_CLAIMS_BY_POLICY_ID',
  LOAD_CLAIMS_BY_POLICY_ID_SUCCESS: 'LOAD_CLAIMS_BY_POLICY_ID_SUCCESS',
  LOAD_CLAIMS_BY_POLICY_ID_ERROR: 'LOAD_CLAIMS_BY_POLICY_ID_ERROR',
  LOAD_WORKLIST_CLAIMS: 'LOAD_WORKLIST_CLAIMS',
  LOAD_WORKLIST_CLAIMS_SUCCESS: 'LOAD_WORKLIST_CLAIMS_SUCCESS',
  LOAD_WORKLIST_CLAIMS_ERROR: 'LOAD_WORKLIST_CLAIMS_ERROR',
  GET_CLAIMS_INSURED_RISK_TYPES: 'GET_CLAIMS_INSURED_RISK_TYPES',
  GET_CLAIMS_INSURED_RISK_TYPES_SUCCESS: 'GET_CLAIMS_INSURED_RISK_TYPES_SUCCESS',
  GET_CLAIMS_INSURED_RISK_TYPES_ERROR: 'GET_CLAIMS_INSURED_RISK_TYPES_ERROR',
  EXPORT_CLAIMS: 'EXPORT_CLAIMS',
  EXPORT_CLAIMS_SUCCESS: 'EXPORT_CLAIMS_SUCCESS',
  EXPORT_CLAIMS_ERROR: 'EXPORT_CLAIMS_ERROR',
  LOAD_CHOOSABLE_CREATED_BY_ORGANIZATIONS: 'LOAD_CHOOSABLE_CREATED_BY_ORGANIZATIONS',
  LOAD_CHOOSABLE_CREATED_BY_ORGANIZATIONS_SUCCESS: 'LOAD_CHOOSABLE_CREATED_BY_ORGANIZATIONS_SUCCESS',
  LOAD_CHOOSABLE_CREATED_BY_ORGANIZATIONS_ERROR: 'LOAD_CHOOSABLE_CREATED_BY_ORGANIZATIONS_ERROR',
  GET_CLAIMS_FILTERS_ORGANIZATIONS_WITH_ACCESS: 'GET_CLAIMS_FILTERS_ORGANIZATIONS_WITH_ACCESS',
  GET_CLAIMS_FILTERS_ORGANIZATIONS_WITH_ACCESS_SUCCESS: 'GET_CLAIMS_FILTERS_ORGANIZATIONS_WITH_ACCESS_SUCCESS',
  GET_CLAIMS_FILTERS_ORGANIZATIONS_WITH_ACCESS_ERROR: 'GET_CLAIMS_FILTERS_ORGANIZATIONS_WITH_ACCESS_ERROR',
  LOAD_MAP_RISKS: 'LOAD_MAP_RISKS',
  LOAD_MAP_RISKS_SUCCESS: 'LOAD_MAP_RISKS_SUCCESS',
  LOAD_MAP_RISKS_ERROR: 'LOAD_MAP_RISKS_ERROR',
  SET_MAP_SELECTED_RISK_ID: 'SET_MAP_SELECTED_RISK',
  LOAD_MAP_SELECTED_RISK: 'LOAD_MAP_SELECTED_RISK',
  LOAD_MAP_SELECTED_RISK_SUCCESS: 'LOAD_MAP_SELECTED_RISK_SUCCESS',
  LOAD_MAP_SELECTED_RISK_ERROR: 'LOAD_MAP_SELECTED_RISK_ERROR',
  LOAD_MAP_SELECTED_RISK_CLAIMS: 'LOAD_MAP_RISK_CLAIMS',
  LOAD_MAP_SELECTED_RISK_CLAIMS_SUCCESS: 'LOAD_MAP_RISK_CLAIMS_SUCCESS',
  LOAD_MAP_SELECTED_RISK_CLAIMS_ERROR: 'LOAD_MAP_RISK_CLAIMS_ERROR',
  SET_MAP_CLUSTERS_LOADING: 'SET_MAP_CLUSTERS_LOADING',
  GET_CLAIM: 'GET_CLAIM',
  GET_CLAIM_SUCCESS: 'GET_CLAIM_SUCCESS',
  GET_CLAIM_ERROR: 'GET_CLAIM_ERROR',
  CREATE_CLAIM: 'CREATE_CLAIM',
  CREATE_CLAIM_SUCCESS: 'CREATE_CLAIM_SUCCESS',
  CREATE_CLAIM_ERROR: 'CREATE_CLAIM_ERROR',
  UPDATE_CLAIM: 'UPDATE_CLAIM',
  UPDATE_CLAIM_SUCCESS: 'UPDATE_CLAIM_SUCCESS',
  UPDATE_CLAIM_ERROR: 'UPDATE_CLAIM_ERROR',
  UPDATE_CLAIM_STATUS: 'UPDATE_CLAIM_STATUS',
  UPDATE_CLAIM_STATUS_SUCCESS: 'UPDATE_CLAIM_STATUS_SUCCESS',
  UPDATE_CLAIM_STATUS_ERROR: 'UPDATE_CLAIM_STATUS_ERROR',
  GET_CLAIM_AUTHORIZED_ORGANIZATIONS: 'GET_CLAIM_AUTHORIZED_ORGANIZATIONS',
  GET_CLAIM_AUTHORIZED_ORGANIZATIONS_SUCCESS: 'GET_CLAIM_AUTHORIZED_ORGANIZATIONS_SUCCESS',
  GET_CLAIM_AUTHORIZED_ORGANIZATIONS_ERROR: 'GET_CLAIM_AUTHORIZED_ORGANIZATIONS_ERROR',
  GET_CLAIM_ORGANIZATIONS_WITH_ACCESS: 'GET_CLAIM_ORGANIZATIONS_WITH_ACCESS',
  GET_CLAIM_ORGANIZATIONS_WITH_ACCESS_SUCCESS: 'GET_CLAIM_ORGANIZATIONS_WITH_ACCESS_SUCCESS',
  GET_CLAIM_ORGANIZATIONS_WITH_ACCESS_ERROR: 'GET_CLAIM_ORGANIZATIONS_WITH_ACCESS_ERROR',
  GET_CLAIM_HISTORY: 'GET_CLAIM_HISTORY',
  GET_CLAIM_HISTORY_SUCCESS: 'GET_CLAIM_HISTORY_SUCCESS',
  GET_CLAIM_HISTORY_ERROR: 'GET_CLAIM_HISTORY_ERROR',
  GET_PDF_REPORT: 'GET_PDF_REPORT',
  GET_PDF_REPORT_SUCCESS: 'GET_PDF_REPORT_SUCCESS',
  GET_PDF_REPORT_ERROR: 'GET_PDF_REPORT_ERROR',
  GET_CLAIM_PDF_SUMMARY: 'GET_CLAIM_PDF_SUMMARY',
  GET_CLAIM_PDF_SUMMARY_SUCCESS: 'GET_CLAIM_PDF_SUMMARY_SUCCESS',
  GET_CLAIM_PDF_SUMMARY_ERROR: 'GET_CLAIM_PDF_SUMMARY_ERROR',
  CLEAR_CLAIM: 'CLEAR_CLAIM',
  CLEAR_CLAIM_ORGANIZATIONS_WITH_ACCESS: 'CLEAR_CLAIM_ORGANIZATIONS_WITH_ACCESS',
  CLEAR_CLAIM_HISTORY: 'CLEAR_CLAIM_HISTORY',
  CLEAR_CLAIM_INSURED_RISK_TYPES: 'CLEAR_CLAIM_INSURED_RISK_TYPES',
  RESET_CLAIM_PDF_SUMMARY: 'RESET_CLAIM_PDF_SUMMARY',
  CLEAR_CREATE_CLAIM_ERROR: 'CLEAR_CREATE_CLAIM_ERROR',
  SET_SHOW_CLAIM_EDIT_OVERVIEW_MODAL: 'SET_SHOW_CLAIM_EDIT_OVERVIEW_MODAL',
  SET_LAST_VISITED_CLAIM_ID: 'SET_LAST_VISITED_CLAIM_ID',
  RESET_LAST_VISITED_CLAIM_ID: 'RESET_LAST_VISITED_CLAIM_ID',
  GET_POLICY_DETAILS_CLAIMS: 'GET_POLICY_DETAILS_CLAIMS',
  GET_POLICY_DETAILS_CLAIMS_SUCCESS: 'GET_POLICY_DETAILS_CLAIMS_SUCCESS',
  GET_POLICY_DETAILS_CLAIMS_ERROR: 'GET_POLICY_DETAILS_CLAIMS_ERROR',
  GET_CLAIMS_BY_DATE_AND_LOCATION: 'GET_CLAIMS_BY_DATE_AND_LOCATION',
  GET_CLAIMS_BY_DATE_AND_LOCATION_SUCCESS: 'GET_CLAIMS_BY_DATE_AND_LOCATION_SUCCESS',
  GET_CLAIMS_BY_DATE_AND_LOCATION_ERROR: 'GET_CLAIMS_BY_DATE_AND_LOCATION_ERROR',
  CLEAR_CLAIMS_BY_DATE_AND_LOCATION: 'CLEAR_CLAIMS_BY_DATE_AND_LOCATION',

  // -----------------------------------ACTIONS FOR CLAIMS LIST-----------------------------------
  loadClaims: (params = {}) => ({
    type: actions.LOAD_CLAIMS,
    params
  }),
  loadClaimsSuccess: (allClaims, meta) => ({
    type: actions.LOAD_CLAIMS_SUCCESS,
    allClaims,
    meta
  }),
  loadClaimsError: () => ({
    type: actions.LOAD_CLAIMS_ERROR
  }),
  loadClaimsByPolicyId: (policyId, otherParams) => ({
    type: actions.LOAD_CLAIMS_BY_POLICY_ID,
    policyId,
    otherParams
  }),
  loadClaimsByPolicyIdSuccess: (allClaimsByPolicyId) => ({
    type: actions.LOAD_CLAIMS_BY_POLICY_ID_SUCCESS,
    allClaimsByPolicyId
  }),
  loadClaimsByPolicyIdError: () => ({
    type: actions.LOAD_CLAIMS_BY_POLICY_ID_ERROR
  }),
  loadWorklistClaims: (params) => ({
    type: actions.LOAD_WORKLIST_CLAIMS,
    params
  }),
  loadWorklistClaimsSuccess: (claims, meta) => ({
    type: actions.LOAD_WORKLIST_CLAIMS_SUCCESS,
    claims,
    meta
  }),
  loadWorklistClaimsError: () => ({
    type: actions.LOAD_WORKLIST_CLAIMS_ERROR
  }),
  getClaimsInsuredRiskTypes: () => ({
    type: actions.GET_CLAIMS_INSURED_RISK_TYPES
  }),
  getClaimsInsuredRiskTypesSuccess: (insuredRiskTypes) => ({
    type: actions.GET_CLAIMS_INSURED_RISK_TYPES_SUCCESS,
    insuredRiskTypes
  }),
  getClaimsInsuredRiskTypesError: () => ({
    type: actions.GET_CLAIMS_INSURED_RISK_TYPES_ERROR
  }),
  exportClaims: (params) => ({
    type: actions.EXPORT_CLAIMS,
    params
  }),
  exportClaimsSuccess: () => ({
    type: actions.EXPORT_CLAIMS_SUCCESS
  }),
  exportClaimsError: () => ({
    type: actions.EXPORT_CLAIMS_ERROR
  }),
  loadChoosableCreatedByOrganizations: () => ({
    type: actions.LOAD_CHOOSABLE_CREATED_BY_ORGANIZATIONS
  }),
  loadChoosableCreatedByOrganizationsSuccess: (organizations) => ({
    type: actions.LOAD_CHOOSABLE_CREATED_BY_ORGANIZATIONS_SUCCESS,
    organizations
  }),
  loadChoosableCreatedByOrganizationsError: () => ({
    type: actions.LOAD_CHOOSABLE_CREATED_BY_ORGANIZATIONS_ERROR
  }),
  getClaimsFiltersOrganizationsWithAccess: () => ({
    type: actions.GET_CLAIMS_FILTERS_ORGANIZATIONS_WITH_ACCESS
  }),
  getClaimsFiltersOrganizationsWithAccessSuccess: (organizations) => ({
    type: actions.GET_CLAIMS_FILTERS_ORGANIZATIONS_WITH_ACCESS_SUCCESS,
    organizations
  }),
  getClaimsFiltersOrganizationsWithAccessError: () => ({
    type: actions.GET_CLAIMS_FILTERS_ORGANIZATIONS_WITH_ACCESS_ERROR
  }),
  loadMapRisks: (params) => ({
    type: actions.LOAD_MAP_RISKS,
    params
  }),
  loadMapRisksSuccess: (mapRisks) => ({
    type: actions.LOAD_MAP_RISKS_SUCCESS,
    mapRisks
  }),
  loadMapRisksError: () => ({
    type: actions.LOAD_MAP_RISKS_ERROR
  }),
  setMapSelectedRiskId: (riskId) => ({
    type: actions.SET_MAP_SELECTED_RISK_ID,
    riskId
  }),
  loadMapSelectedRisk: (riskId) => ({
    type: actions.LOAD_MAP_SELECTED_RISK,
    riskId
  }),
  loadMapSelectedRiskSuccess: (risk) => ({
    type: actions.LOAD_MAP_SELECTED_RISK_SUCCESS,
    risk
  }),
  loadMapSelectedRiskError: () => ({
    type: actions.LOAD_MAP_SELECTED_RISK_ERROR
  }),
  loadMapSelectedRiskClaims: (riskId, params) => ({
    type: actions.LOAD_MAP_SELECTED_RISK_CLAIMS,
    riskId,
    params
  }),
  loadMapSelectedRiskClaimsSuccess: (riskClaims) => ({
    type: actions.LOAD_MAP_SELECTED_RISK_CLAIMS_SUCCESS,
    riskClaims
  }),
  loadMapSelectedRiskClaimsError: () => ({
    type: actions.LOAD_MAP_SELECTED_RISK_CLAIMS_ERROR
  }),
  setMapClustersLoading: (loading) => ({
    type: actions.SET_MAP_CLUSTERS_LOADING,
    loading
  }),
  setLastVisitedClaimId: (claimId) => ({
    type: actions.SET_LAST_VISITED_CLAIM_ID,
    claimId
  }),
  resetLastVisitedClaimId: () => ({
    type: actions.RESET_LAST_VISITED_CLAIM_ID
  }),

  // -----------------------------------ACTIONS FOR SINGLE CLAIM----------------------------------- //
  getClaim: (claimId) => ({
    type: actions.GET_CLAIM,
    claimId
  }),
  getClaimSuccess: (claim) => ({
    type: actions.GET_CLAIM_SUCCESS,
    claim
  }),
  getClaimError: () => ({
    type: actions.GET_CLAIM_ERROR
  }),
  createClaim: (claimData, params, callback, navigate) => ({
    type: actions.CREATE_CLAIM,
    claimData,
    params,
    callback,
    navigate
  }),
  createClaimSuccess: (claim, params) => ({
    type: actions.CREATE_CLAIM_SUCCESS,
    claim,
    params
  }),
  createClaimError: (error) => ({
    type: actions.CREATE_CLAIM_ERROR,
    error
  }),
  updateClaim: (claim, callback) => ({
    type: actions.UPDATE_CLAIM,
    claim,
    callback
  }),
  updateClaimSuccess: (claim) => ({
    type: actions.UPDATE_CLAIM_SUCCESS,
    claim
  }),
  updateClaimError: () => ({
    type: actions.UPDATE_CLAIM_ERROR
  }),
  updateClaimStatus: (claimId, status, callback) => ({
    type: actions.UPDATE_CLAIM_STATUS,
    claimId,
    status,
    callback
  }),
  updateClaimStatusSuccess: (claim, claimId, callback) => ({
    type: actions.UPDATE_CLAIM_STATUS_SUCCESS,
    claim,
    claimId,
    callback
  }),
  updateClaimStatusError: (errorMessage) => ({
    type: actions.UPDATE_CLAIM_STATUS_ERROR,
    errorMessage
  }),
  getClaimAuthorizedOrganizations: (claimId) => ({
    type: actions.GET_CLAIM_AUTHORIZED_ORGANIZATIONS,
    claimId
  }),
  getClaimAuthorizedOrganizationsSuccess: (authorizedOrganizations) => ({
    type: actions.GET_CLAIM_AUTHORIZED_ORGANIZATIONS_SUCCESS,
    authorizedOrganizations
  }),
  getClaimAuthorizedOrganizationsError: (error) => ({
    type: actions.GET_CLAIM_AUTHORIZED_ORGANIZATIONS_ERROR,
    error
  }),
  getClaimOrganizationsWithAccess: (claimId) => ({
    type: actions.GET_CLAIM_ORGANIZATIONS_WITH_ACCESS,
    claimId
  }),
  getClaimOrganizationsWithAccessSuccess: (organizations) => ({
    type: actions.GET_CLAIM_ORGANIZATIONS_WITH_ACCESS_SUCCESS,
    organizations
  }),
  getClaimOrganizationsWithAccessError: () => ({
    type: actions.GET_CLAIM_ORGANIZATIONS_WITH_ACCESS_ERROR
  }),
  getClaimHistory: (claimId) => ({
    type: actions.GET_CLAIM_HISTORY,
    claimId
  }),
  getClaimHistorySuccess: (history) => ({
    type: actions.GET_CLAIM_HISTORY_SUCCESS,
    payload: history
  }),
  getClaimHistoryError: () => ({
    type: actions.GET_CLAIM_HISTORY_ERROR
  }),
  getPDFReport: (claimId, callback) => ({
    type: actions.GET_PDF_REPORT,
    claimId,
    callback
  }),
  getPDFReportSuccess: () => ({
    type: actions.GET_PDF_REPORT_SUCCESS
  }),
  getPDFReportError: () => ({
    type: actions.GET_PDF_REPORT_ERROR
  }),
  // ? This action may do the same as getPDFReport
  getClaimPDFSummary: (claimId, isMessage) => ({
    type: actions.GET_CLAIM_PDF_SUMMARY,
    claimId,
    isMessage
  }),
  getClaimPDFSummarySuccess: (pdfClaimSummary) => ({
    type: actions.GET_CLAIM_PDF_SUMMARY_SUCCESS,
    pdfClaimSummary
  }),
  getClaimPDFSummaryError: () => ({
    type: actions.GET_CLAIM_PDF_SUMMARY_ERROR
  }),
  clearClaim: () => ({
    type: actions.CLEAR_CLAIM
  }),
  clearClaimOrganizationsWithAccess: () => ({
    type: actions.CLEAR_CLAIM_ORGANIZATIONS_WITH_ACCESS
  }),
  clearClaimHistory: () => ({
    type: actions.CLEAR_CLAIM_HISTORY
  }),
  resetClaimPDFSummary: () => ({
    type: actions.RESET_CLAIM_PDF_SUMMARY
  }),
  clearCreteClaimErrors: () => ({
    type: actions.CLEAR_CREATE_CLAIM_ERROR
  }),
  clearClaimInsuredRiskTypes: () => ({
    type: actions.CLEAR_CLAIM_INSURED_RISK_TYPES
  }),
  setShowClaimEditOverviewModal: (showModal) => ({
    type: actions.SET_SHOW_CLAIM_EDIT_OVERVIEW_MODAL,
    showModal
  }),
  getPolicyDetailsClaims: (policyId, search) => ({
    type: actions.GET_POLICY_DETAILS_CLAIMS,
    policyId,
    search
  }),
  getPolicyDetailsClaimsSuccess: (claims) => ({
    type: actions.GET_POLICY_DETAILS_CLAIMS_SUCCESS,
    claims
  }),
  getPolicyDetailsClaimsError: () => ({
    type: actions.GET_POLICY_DETAILS_CLAIMS_ERROR
  }),

  // -----------------------------------ACTIONS FOR CREATE CLAIM----------------------------------- //
  getClaimsByDateAndLocation: (params) => ({
    type: actions.GET_CLAIMS_BY_DATE_AND_LOCATION,
    params
  }),
  getClaimsByDateAndLocationSuccess: (claims) => ({
    type: actions.GET_CLAIMS_BY_DATE_AND_LOCATION_SUCCESS,
    claims
  }),
  getClaimsByDateAndLocationError: () => ({
    type: actions.GET_CLAIMS_BY_DATE_AND_LOCATION_ERROR
  }),
  clearClaimsByDateAndLocation: () => ({
    type: actions.CLEAR_CLAIMS_BY_DATE_AND_LOCATION
  })
};

export default actions;
