import React from 'react';
import styled from 'styled-components';
import { windowMaxWidth } from '../Layout/breakpoints';

const InformationBannerWrapper = styled.div`
  align-items: center;
  background-color: ${(props) => props.$backgroundColor || color_dictionary.grey};
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 40px;
  justify-content: flex-start;
  padding: 10px 30px;
  width: 100%;

  svg {
    display: flex;
    flex: 0 0 20px;
    height: 20px;
    width: 20px;

    path {
      stroke: var(--greyscale-white);

      &.dot {
        fill: var(--greyscale-white);
        stroke: unset;
      }
    }
  }

  @media ${windowMaxWidth.splitScreen} {
    height: auto;
  }
`;

const InformationBannerText = styled.span`
  color: var(--greyscale-white);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-line-height-regular);
`;

const color_dictionary = {
  blue: 'var(--primary-oqio-blue)',
  green: 'var(--primary-oqio-green)',
  red: 'var(--primary-oqio-red)',
  grey: 'var(--greyscale-shade)'
};

const InformationBanner = ({ color, icon, text }) => {
  return (
    <InformationBannerWrapper $backgroundColor={color_dictionary[color]}>
      {icon} <InformationBannerText>{text}</InformationBannerText>
    </InformationBannerWrapper>
  );
};

export { InformationBanner };
